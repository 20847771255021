// extracted by mini-css-extract-plugin
export var boldText = "style-module--bold-text--090ab";
export var cardImage = "style-module--card-image--a8694";
export var cardImageImg = "style-module--card-image-img--1fe3c";
export var cardRow = "style-module--card-row--03086";
export var cardValuesHeader = "style-module--card-values-header--dd674";
export var description = "style-module--description--1318a";
export var getStarted = "style-module--get-started--51450";
export var getStartedButton = "style-module--get-started-button--e3933";
export var heading = "style-module--heading--8e303";
export var interestRateHeader = "style-module--interest-rate-header--cf085";
export var listHeader = "style-module--list-header--9978c";
export var rateTableHeader = "style-module--rate-table-header--ccd8a";
export var seeDetailsBelow = "style-module--see-details-below--e896d";
export var subHeading = "style-module--sub-heading--122ad";