import React, { useState } from "react"
import TermDepositProduct from "models/product-models/TermDepositProduct"
import Provider from "models/Provider"
import Layout from "components/layout"
import ReturnLinkChain from "components/content/return-link-chain"
import { Typography } from "components/typography"
import * as styles from "./style.module.scss"
import ProductComponent from "components/content/term-deposits/product-component"
import { ListHeaderProduct } from "components/content/list-header"
import { TermDeposit as ProductDetails } from "components/content/product-details"
import useMediaQuery from "@mui/material/useMediaQuery"
import AppManager from "services/app-manager"
import TermDepositProvider from "models/provider-models/TermDepositProvider"
import { SavingsAccountAds, TermDepositAds } from "components/content/ads"
import FactCheckedDialog from "components/content/fact-checked-dialog"

function makeReviewComponent(reviews: string[], p3Array: { [id: string]: any }[]): JSX.Element {
  return (
    <div>
      {reviews.map((review, key) => (
        <Typography.P style={{ marginBottom: "15px" }}>
          {review}{" "}
          {p3Array.map(p3 => (
            <div>
              <Typography.H6>{p3.heading}</Typography.H6>
              {p3.paragraph}
            </div>
          ))}
        </Typography.P>
      ))}
    </div>
  )
}

const ProductTemplate = (props: any) => {
  const { provider, productId, dateUpdated, ads } = JSON.parse(props.pageContext.p)

  const isWide = useMediaQuery("(min-width:768px)")
  const product: TermDepositProduct = TermDepositProduct.Initialise(provider.products[productId] as TermDepositProduct)
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dateString = Number(dateUpdated.substring(8, 10)) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4)

  const appManager = AppManager.getInstance()
  const [investmentAmount, setInvestmentAmount] = useState(appManager.getDepositInvestmentAmount())
  const [investmentTerm, setInvestmentTerm] = useState(appManager.getDepositInvestmentTerm())

  let queryString = ""
  if (typeof window !== "undefined") {
    queryString = window.location.search
  }
  const urlParams = new URLSearchParams(queryString)
  let rateId = props.location.state ? props.location.state.rateId : urlParams.get("rateId")

  let rate = null
  if (rateId == null) {
    let maxRate = Number.MIN_VALUE
    Object.keys(product.rates).forEach(rId => {
      const r = product.rates[rId]
      if (r.interestRate > maxRate) {
        maxRate = r.interestRate
        rateId = rId
        rate = r
      }
    })
  } else {
    rate = product.rates[rateId]
  }

  const hasOffer = Object.values(product.offers).some(o => {
    return o.active === "Y" && o.landingPageUrl
  })

  const ProductInterestRate = () => {
    type termDetail = {
      monthlyInterest: number | null | undefined
      maturityInterest: number | null | undefined
      annualInterest: number | null | undefined
    }
    type tierData = {
      minInvestment: number
      maxInvestment: number | null | undefined
      termDetails: { [key: string]: termDetail }
    }

    const tierLists: { [key: string]: JSX.Element[] } = {}
    const allTierData: { [key: string]: tierData } = {}

    const sortedRates = Object.keys(product.rates).sort((a, b) => {
      const rateA = product.rates[a]
      const rateB = product.rates[b]

      const rateAMinTerm = TermDepositProduct.getTermInMonths(rateA.sogMinTerm)
      const rateBMinTerm = TermDepositProduct.getTermInMonths(rateB.sogMinTerm)

      return rateAMinTerm - rateBMinTerm
    })

    sortedRates.forEach(rateId => {
      const r = product.rates[rateId]

      const tierKey = `${r.minInvestment}-${r.maxInvestment || "NULL"}`

      const tier: tierData =
        tierKey in allTierData
          ? allTierData[tierKey]
          : {
              minInvestment: r.minInvestment,
              maxInvestment: r.maxInvestment,
              termDetails: {},
            }
      allTierData[tierKey] = tier

      const termInMonths = TermDepositProduct.getTermInMonths(r.sogMinTerm)

      const term: termDetail =
        termInMonths in tier.termDetails
          ? tier.termDetails[termInMonths]
          : {
              monthlyInterest: null,
              annualInterest: null,
              maturityInterest: null,
            }
      tier.termDetails[termInMonths] = term

      if (r.applicationFreq !== null) {
        if (r.applicationFreq === "MATURITY") {
          term.maturityInterest = r.interestRate
        } else {
          const appFreq = TermDepositProduct.getTermInMonths(r.applicationFreq)
          if (appFreq === 1) {
            term.monthlyInterest = r.interestRate
          }
          if (appFreq === 12) {
            term.annualInterest = r.interestRate
          }
        }
      }
    })

    const sortedTiers = Object.keys(allTierData).sort((a, b) => {
      const tierA = allTierData[a]
      const tierB = allTierData[b]

      return tierA.minInvestment - tierB.minInvestment
    })

    sortedTiers.forEach(tierKey => {
      const tier = allTierData[tierKey]
      const sortedTerms = Object.keys(tier.termDetails).sort((a, b) => {
        return parseInt(a) - parseInt(b)
      })
      sortedTerms.forEach(termKey => {
        const termInt = parseInt(termKey)
        const term = tier.termDetails[termKey]

        const termRow = (
          <div style={{ display: "flex", borderBottom: "0.5px solid #D2D2D2", paddingTop: "15px", paddingBottom: "15px" }}>
            <div style={{ width: "33%" }}>
              <Typography.P comp="product-detail">{`${termInt} month${termInt > 1 ? "s" : ""}`}</Typography.P>
            </div>
            <div style={{ width: "33%", textAlign: "right" }}>
              <Typography.P comp="product-detail-bold">{term.monthlyInterest ? (term.monthlyInterest * 100).toFixed(2) + "%" : "-"}</Typography.P>
            </div>
            <div style={{ width: "33%", textAlign: "right" }}>
              <Typography.P comp="product-detail-bold">{term.maturityInterest ? (term.maturityInterest * 100).toFixed(2) + "%" : term.annualInterest ? (term.annualInterest * 100).toFixed(2) + "%" : "-"}</Typography.P>
            </div>
          </div>
        )

        if (tierKey in tierLists) {
          tierLists[tierKey].push(termRow)
        } else {
          tierLists[tierKey] = [termRow]
        }
      })
    })

    return (
      <div style={{ marginTop: "40px" }}>
        {Object.keys(tierLists).length > 0 ? (
          Object.keys(tierLists).map(tierKey => (
            <div>
              <div className={styles.interestRateHeader}>
                <span style={{ width: "67%" }}>{`$${allTierData[tierKey].minInvestment.toLocaleString("en")}${allTierData[tierKey].maxInvestment && (allTierData[tierKey].maxInvestment || 0) + 1 < (product.maxInvestment || 0) && product.maxInvestment !== null ? ` up to $${(allTierData[tierKey].maxInvestment || "").toLocaleString("en")}` : "+"} deposit`}</span>
                <span style={{ width: "33%", textAlign: "right" }}>{"(% p.a.)"}</span>
              </div>
              {isWide ? (
                <div className={styles.rateTableHeader}>
                  <div style={{ width: "33%" }}>Term</div>
                  <div style={{ width: "33%", textAlign: "right" }}>Monthly Interest</div>
                  <div style={{ width: "33%", textAlign: "right" }}>
                    Interest at Maturity/
                    <br />
                    Annually
                  </div>
                </div>
              ) : (
                <div className={styles.rateTableHeader}>
                  <div style={{ width: "33%" }}>Term</div>
                  <div style={{ width: "33%", textAlign: "right" }}>
                    Monthly
                    <br />
                    Interest
                  </div>
                  <div style={{ width: "33%", textAlign: "right" }}>
                    Interest
                    <br />
                    at Maturity/
                    <br />
                    Annually
                  </div>
                </div>
              )}
              {tierLists[tierKey]}
            </div>
          ))
        ) : (
          <div style={{ textAlign: "center", fontSize: "24px" }}>No Rates</div>
        )}
      </div>
    )
  }

  const expertReviewSection = () => {
    return (
      <div>
        <ListHeaderProduct>Expert Review</ListHeaderProduct>
        {product.getExpertReview(provider, makeReviewComponent)}
      </div>
    )
  }

  let breadcrumbName = product.name.replace(provider.name, "") || product.name

  return (
    // <Layout title={`${nameHeading} - Review | Stay or Go`} description={product.autoDescription} urlSlug={product.urlSlug ? product.urlSlug : undefined} dateUpdated={dateUpdated}>
    <Layout title={product.nameLong + ` - Review | Stay or Go`} description={product.autoDescription} urlSlug={product.urlSlug ? product.urlSlug : undefined} dateUpdated={dateUpdated} includeMozoDisclaimer>
      <div className="page-container">
        <div className="content-provider-container">
          <ReturnLinkChain
            linkChain={[
              { name: "Term Deposits", to: "/term-deposits/" },
              { name: provider.name, to: "/" + provider.urlSlug + "-term-deposit-rates" },
            ]}
            currentLocationItem={{ name: breadcrumbName }}
            excludeBottomMargin={isWide}
            includeFactCheckedDialog={isWide}
            factCheckedDialogDateUpdated={dateString}
          />
          <div className={styles.heading}>
            {/* {makeSchemaOrg(product, provider, nameHeading)} */}
            <Typography.H1>{product.nameLong}</Typography.H1>
          </div>
          {product.forSale === "GRANDFATHERED" ? <div className={styles.subHeading}>(no longer for sale)</div> : null}
          {product.forSale === "PAUSED" ? <div className={styles.subHeading}>(currently not for sale)</div> : null}
          <div className={styles.description}>
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              {product.autoDescription}
            </Typography.P>
            <br />
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              Updated {dateString}
            </Typography.P>
            {!isWide && (
              <>
                <br />
                <FactCheckedDialog dateString={dateString} />
              </>
            )}
          </div>
          <div className="show-for-desktop">
            <ProductComponent product={product} rateId={rateId || undefined} hideName isOfferDetails />
          </div>
          <div className="show-for-mobile">
            <ProductComponent product={product} rateId={rateId || undefined} hideName mobileProduct isOfferDetails />
          </div>
          {/* <Typography.P className={styles.seeDetailsBelow} comp={"flat-18"}>
            * See details below
          </Typography.P> */}
          {ads && !hasOffer ? ads.product.id.includes("TD") ? <TermDepositAds ads={ads} /> : ads.product.id.includes("SV") ? <SavingsAccountAds ads={ads} /> : null : null}
          <ListHeaderProduct>Interest Rates</ListHeaderProduct>
          {/* <InputFilter setHomeLoanAmount={setHomeLoanAmount} setLoanDeposit={setLoanDeposit} setLoanPurpose={setLoanPurpose} /> */}
          <ProductInterestRate />
          {/* {expertReviewSection()} */}
          <ListHeaderProduct>Product Details</ListHeaderProduct>
          {product.description !== null ? (
            <div style={{ marginBottom: "30px", marginTop: "30px" }}>
              <div style={{ color: "#797979", fontFamily: "Arboria-Book, sans-serif", fontSize: "16px", display: "flex" }}>
                <img alt="" src="/images/icon_quote.png" style={{ width: "20px", height: "15.66px", marginRight: "15px" }} />
                <div>{product.description}"</div>
              </div>
              <div style={{ color: "#E7527A", fontFamily: "Arboria-Bold, sans-serif", textAlign: "right", fontSize: "16px" }}>- {provider.name}</div>
            </div>
          ) : null}
          <ProductDetails product={product} provider={provider as TermDepositProvider} />
          {/* {provider.getAlternativeProductList(product, makeProductComponent).length > 0 ? (
            <div>
              <div className={styles.listHeader} style={{ marginTop: "0px" }}>
                <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "22px" }}>Alternative Credit Cards</div>
              </div>
              <ListHeaderProduct style={{ marginTop: "0px" }}>Alternative Credit Cards</ListHeaderProduct>
            </div>
          ) : null}
          {provider.getAlternativeProductList(product, makeProductComponent)} */}
          {/* <TermDepositDisclaimer isDisclaimerOpen /> */}
        </div>
      </div>
    </Layout>
  )
}

export default ProductTemplate
